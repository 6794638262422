import { createReducer, on } from '@ngrx/store';
import { Country } from '../../models/country.model';
import { loadCountries, loadCountriesSuccess, loadCountriesFailed, setStatesCountries, unsetStatesCountries } from '../../actions/country/countries.action';

export interface State {
    countries: Country[] | null;
    loaded: boolean;
    loading: boolean;
    error: any;

    states: any[] | null;
}

export const initialState: State = {
   countries: null,
   loaded: false,
   loading: false,
   error: null,
   states: null
};

const _countriesReducer = createReducer(initialState,

    on(loadCountries, state => ({ ...state, loading: true})),
    on(loadCountriesSuccess, (state, { countries }) => ({
        ...state,
        loading: false,
        loaded: false,
        countries: [...countries]
    })),
    on(loadCountriesFailed, (state, { error }) => ({
        ...state,
        loading: false,
        loaded: false,
        eror: {...error}
    })),
    on(setStatesCountries, (state, { statesCountries }) => ({
        ...state,
        states: [...statesCountries]
    })),
    on(unsetStatesCountries, (state) => ({
        ...state,
        states: null
    })),

);

export function countriesReducer(state, action) {
    return _countriesReducer(state, action);
}
import { Component, OnInit,OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { EventService } from '../../core/services/event.service';

import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { unsetAuthenticatedUser } from '../../core/actions/user/user.action';
import { setLanguage } from '../../core/actions/app/language.actions';
import { User } from '../../core/models/user.models';
import { unsetScreensSelecteds, setScreensSelecteds, setPublicTransportsSelecteds } from '../../core/actions/directory/directory-selecteds-screens.actions';
import { LocalService } from '../../core/services/local.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions = new Subscription();

  element;
  configData;

  user: User;

  totalScreensSelected = 0;

  companyImg: string;

  pointsSelected = 0;

  totalPublicTransportsSelected = 0;

  isFullAdvertising: boolean = false;

  navbarShowHide: boolean = true;

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private eventService: EventService,
    private store: Store<AppState>,
    private localService: LocalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subscriptions.add(
      router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    }));
  }

  ngOnInit(): void {    
    if (window.location.hostname === 'fullooh.com') {
      this.isFullAdvertising = true;
    }
    
    this.element = document.documentElement;

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3
    };

    this.loadUser();
    this.loadScreensSelected();
    this.loadPublicTransportSelected();
    this.loadPointsSelected();
    this.getScreensLocalStorage();
    this.getPublicTransportsLocalStorage();
    this.loadLogo();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * logout method
   */
  logout() {
    this.store.dispatch( unsetAuthenticatedUser() );
    this.router.navigateByUrl('/account/login');
  }

  changeLanguage(language: string) {
    this.store.dispatch( setLanguage({language}) );
  }

  loadUser() {
    this.subscriptions.add(
      this.store.select('user').subscribe( ({user}) => {
      this.user = user;
    }));
  }

  loadScreensSelected() {
    // tslint:disable-next-line: max-line-length
    this.subscriptions.add(
      this.store.select( 'directorySelectedsScreen' ).subscribe( ({markersScreensSelected}) => this.totalScreensSelected = markersScreensSelected.length));
  }

  loadPublicTransportSelected() {
    // tslint:disable-next-line: max-line-length
    this.store.select( 'directorySelectedsScreen' ).subscribe( ({markersPublicTransportsSelecteds}) => this.totalPublicTransportsSelected = markersPublicTransportsSelecteds.length);
  }

  loadPointsSelected() {
    this.subscriptions.add(
      this.localService.itemValue.subscribe((ok) => {
      this.pointsSelected = this.localService.getLocal( 'points' ) ? this.localService.getLocal( 'points' ).length : 0;
    }));
  }

  removeScreensSelecteds() {
    this.store.dispatch( unsetScreensSelecteds() );
  }

  removePointsSelecteds() {
    this.pointsSelected = 0;
    this.localService.deleteLocal( 'points' );
  }

  createNewCampaign() {
    this.router.navigateByUrl( '/campaigns/registry' );
  }

  getScreensLocalStorage() {
    this.store.dispatch(setScreensSelecteds({markers: this.localService.getLocal('screens_selecteds') || []}));
  }

  getPublicTransportsLocalStorage() {
    this.store.dispatch(setPublicTransportsSelecteds({markers: this.localService.getLocal('public_transports_selecteds') || []}));
  }

  loadLogo() {
    this.subscriptions.add(
      this.store.select( 'ui' ).subscribe( ({companyImg}) => {
        if ( companyImg ) {
          this.companyImg = companyImg;
        }
      }
    ));
  }
}

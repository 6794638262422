import { ActionReducerMap } from '@ngrx/store';
import * as auth from './core/reducers/app/auth.reducer';
import * as language from './core/reducers/app/language.reducer';
import * as user from './core/reducers/user/user.reducer';
import * as ui from './core/reducers/app/ui.reducer';
import * as countries from './core/reducers/country/countries.reducer';
import * as screens from './core/reducers/screen/screens.reducer';
import * as directory from './core/reducers/directory/directory.reducer';
import * as directorySelectedsScreens from './core/reducers/directory/directory-selecteds-screens.reducer';
import * as users from './core/reducers/user/users.reducer';
import * as pagination from './core/reducers/app/pagination.reducer';
import * as screen from './core/reducers/screen/screen.reducer';
import * as campaigns from './core/reducers/campaign/campaigns.reducer';
import * as campaign from './core/reducers/campaign/campaign.reducer';
import * as companies from './core/reducers/company/companies.reducer';
import * as company from './core/reducers/company/company.reducer';
import * as places from './core/reducers/google/places.reducer';
import * as circle from './core/reducers/google/circle.reducer';
import * as search from './core/reducers/app/search.reducer';
import * as impacts from './core/reducers/impacts/impacts.reducer';
import * as publicTransports from './core/reducers/public-transport/public-transports.reducer';
import * as meters from './core/reducers/google/meters.reducers';
import * as filter from './core/reducers/campaign/filter.reducer';

export interface AppState {
    auth: auth.State;
    language: language.State;
    ui: ui.State;
    user: user.State;
    countries: countries.State;
    screens: screens.State;
    directory: directory.State;
    directorySelectedsScreen: directorySelectedsScreens.State;
    users: users.State;
    pagination: pagination.State;
    screen: screen.State;
    campaigns: campaigns.State;
    campaign: campaign.State;
    companies: companies.State;
    company: company.State;
    places: places.State;
    circle: circle.State;
    search: search.State;
    impacts: impacts.State;
    publicTransports: publicTransports.State;
    meters: meters.State;
    filter: filter.State
}

export const appReducers: ActionReducerMap<AppState> = {
    auth: auth.authReducer,
    language: language.languageReducer,
    ui: ui.uiReducer,
    user: user.userReducer,
    countries: countries.countriesReducer,
    screens: screens.screensReducer,
    directory: directory.directoryReducer,
    directorySelectedsScreen: directorySelectedsScreens.directorySelectedsScreenReducer,
    users: users.usersReducer,
    pagination: pagination.paginationReducer,
    screen: screen.screenReducer,
    campaigns: campaigns.campaignsReducer,
    campaign: campaign.campaignReducer,
    companies: companies.companiesReducer,
    company: company.companyReducer,
    places: places.placesReducer,
    circle: circle.circleReducer,
    search: search.searchReducer,
    impacts: impacts.impactsReducer,
    publicTransports: publicTransports.publicTransportReducer,
    meters: meters.metersReducer,
    filter: filter.filterReducer
};

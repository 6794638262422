import { createReducer, on } from '@ngrx/store';
import { showCircles, setMeters } from '../../actions/google/circle.action';

export interface State {
    showCircles: boolean;
    meters: number | null;
}

export const initialState: State = {
   showCircles: false,
   meters: 100
};

const _circleReducer = createReducer(initialState,

    on(showCircles, (state, {showCircles}) => ({ ...state, showCircles})),
    on( setMeters, (state, {meters}) => ({...state, meters}))

);

export function circleReducer(state, action) {
    return _circleReducer(state, action);
}

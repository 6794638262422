import { createReducer, on } from '@ngrx/store';
import { Screen } from '../../models/screen.model';
import { loadScreens, loadScreensSuccess, loadScreensFailed, setScreensPaginated, unsetScreensPaginated, unsetScreen } from '../../actions/screen/screens.action';
import { PaginatedData } from '../../models/paginated-data.model';

export interface State {
    ooh: string | undefined;
    countries_ids: number[] | undefined;
    states: string[] | undefined;
    typeS: string;
    screens: Screen[] | null;
    loaded: boolean;
    loading: boolean;
    error: any;
    screensPaginated: PaginatedData | null;
}

export const initialState: State = {
    ooh: undefined,
    countries_ids: undefined,
    states: undefined,
    typeS: 'programatic',
    screens: null,
    loaded: false,
    loading: false,
    error: null,
    screensPaginated: null
}

const _screensReducer = createReducer(initialState,

    on(loadScreens, (state, payload) => ({
        ...state,
        ooh: payload.ooh,
        countries_ids: payload.countries_ids,
        states: payload.states,
        typeS: payload.typeS,
        screens: null,
        loading: true
    })),

    on(loadScreensSuccess, (state, { screens }) => ({
        ...state,
        screens: [...screens],
        loaded: true,
        loading: false
    })),

    on(loadScreensFailed, (state, {error}) => ({
        ...state,
        screens: null,
        loaded: false,
        loading: false,
        error: {...error}
    })),

    on(setScreensPaginated, (state, {screensPaginated}) => ({
        ...state,
        screensPaginated: {...screensPaginated}
    })),

    on(unsetScreensPaginated, state => ({
        ...state,
        screensPaginated: null
    })),

    on(unsetScreen, (state, {screenId}) => ({
        ...state,
        screensPaginated: {
            ...state.screensPaginated,
            data: state.screensPaginated.data.filter(screen => screen.id !== screenId)
        }
    }))

);

export function screensReducer(state, action) {
    return _screensReducer(state, action);
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoimagePipe } from './pipes/noimage.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

@NgModule({
  declarations: [NoimagePipe, SanitizeHtmlPipe],
  imports: [
    CommonModule,
  ],
  exports: [NoimagePipe, SanitizeHtmlPipe]
})
export class SharedModule {}

import { createReducer, on } from '@ngrx/store';
import { PaginatedData } from '../../models/paginated-data.model';
import { setCompaniesPaginated, unsetCompaniesPaginated } from '../../actions/company/companies.action';

export interface State {
    companiesPaginated: PaginatedData | null;
}

export const initialState: State = {
    companiesPaginated: null
};

const _companiesReducer = createReducer(initialState,
    on(setCompaniesPaginated, (state, {companiesPaginated}) => ({ ...state, companiesPaginated: {...companiesPaginated}})),
    on(unsetCompaniesPaginated, state => ({ ...state, companiesPaginated: null})),
);

export function companiesReducer(state, action) {
    return _companiesReducer(state, action);
}
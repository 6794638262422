import { createReducer, on } from '@ngrx/store';
import { PaginatedData } from '../../models/paginated-data.model';
import {
    setCampaignsPaginated, unsetCampaignsPaginated,
    setCampaignDuplicate, setCampaignUpdated
} from '../../actions/campaign/campaigns.action';
import { RouteReuseStrategy } from '@angular/router';
import { unsetCampaign } from '../../actions/campaign/campaigns.action';

export interface State {
    campaignsPaginated: PaginatedData | null;
}

export const initialState: State = {
    campaignsPaginated: null
};

const _campaignsReducer = createReducer(initialState,

    on(setCampaignsPaginated, (state, {campaignsPaginated}) => ({ ...state, campaignsPaginated: {...campaignsPaginated}})),
    on(unsetCampaignsPaginated, state => ({ ...state, campaignsPaginated: null})),
    on(setCampaignDuplicate, (state, {campaignDuplicate}) => ({
        ...state,
        campaignsPaginated: {...state.campaignsPaginated, data: [...state.campaignsPaginated.data, {...campaignDuplicate}]}
    })),
    on(setCampaignUpdated, (state, {campaignUpdated}) => ({
        ...state,
        campaignsPaginated: {
            ...state.campaignsPaginated,
            data: state.campaignsPaginated.data.map(cP => {
                if ( cP.id === campaignUpdated.id ) {
                    return {
                        ...campaignUpdated
                    };
                } else {
                    return {...cP};
                }
            })
        }
    })),
    on(unsetCampaign, (state, {campaignId}) => ({
        ...state,
        campaignsPaginated: {
            ...state.campaignsPaginated,
            data: state.campaignsPaginated.data.filter(campaign => campaign.id !== campaignId)
        }
    }))
);

export function campaignsReducer(state, action) {
    return _campaignsReducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { PaginatedData } from '../../models/paginated-data.model';

export const setCompaniesPaginated = createAction(
    '[Companies] Set Companies Paginated',
    props<{companiesPaginated: PaginatedData}>()
);

export const unsetCompaniesPaginated = createAction(
    '[Companies] Unset Companies Paginated'
);

import { Component, Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './app.reducers';
import { setLanguage } from './core/actions/app/language.actions';
import { CookieService } from './core/services/cookie.service';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
  'en': {
    weekdays: ['Mon', 'Tu', 'Wed', 'Th', 'Fri', 'Sat', 'Sun'],
    months: [
      "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"
    ]
  },
  'es': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: [
      "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ]
  }
};

@Injectable()
export class I18n {

  constructor(
    private cookieService: CookieService
  ) {}

  language = this.cookieService.getCookie('language');
}

  // Define custom service providing the months and weekdays translations
  @Injectable()
  export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public activeLang = 'es';
  public availableLangs: string[] = ['es', 'en', 'pt'];

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private cookieService: CookieService
  ) {
    this.translate.setDefaultLang(this.activeLang);
    this.translate.use(this.activeLang);

    if (this.availableLangs.includes(this.translate.getBrowserLang())) {
      this.activeLang = this.translate.getBrowserLang();
    }

    if (this.cookieService.getCookie('language')) {
      this.activeLang = this.cookieService.getCookie('language');
    }

    this.store.dispatch( setLanguage( { language: this.activeLang } ) );

    this.store.select('language').subscribe( ( {language} ) => {
      this.activeLang = language;
      this.translate.use(this.activeLang);
      this.cookieService.setCookie('language', language, 1000);
    });
  }

  ngOnInit() {}

}

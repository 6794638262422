import { Injectable } from '@angular/core';
import { Auth } from '../models/auth.models';
import { CookieService } from './cookie.service';
import { APPCONFIG } from '../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { setAuthenticatedUser } from '../actions/user/user.action';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: Auth;
    token: any;

    constructor(
        private cookieService: CookieService,
        private http: HttpClient,
        private store: Store<AppState>
    ) {
        this.loadCookie();
        this.store.dispatch( setAuthenticatedUser( { authenticatedUser: this.token } ) );
    }

    private getQuery( query: string ) {
        const headers = new HttpHeaders({
          Authorization: `${ this.token.token_type } ${ this.token.access_token }`
        });
        return this.http.get( `${ APPCONFIG.endpoints.services }/${ query }`, { headers } );
    }

    login( userAuth: Auth) {
        userAuth = Object.assign( APPCONFIG.serverConfig, userAuth );
        return this.http.post( `${ APPCONFIG.endpoints.login }`, userAuth )
            .pipe(
                map ( resp => {
                    this.token = resp;
                    this.cookieService.setCookie(`${ APPCONFIG.sessionCookieStorageKey }`, ( JSON.stringify( resp )), 30);
                    return resp;
                })
            );
    }

    logout() {
        this.cookieService.deleteCookie( APPCONFIG.sessionCookieStorageKey );
        this.cookieService.deleteCookie( 'cookie-platform' );
        return this.getQuery( 'users/logout' );
    }

    me() {
        return this.getQuery( 'users/me' )
            .pipe(
                map( resp => {
                    const newToken = {...this.token, ...resp};
                    this.cookieService.setCookie( `${ APPCONFIG.sessionCookieStorageKey }`, ( JSON.stringify( newToken ) ), 30 );
                    // Token convinado con la información del usuario
                    return newToken;
                })
            );
    }

    authenticated() {
        return this.getQuery('users/authenticated');
    }

    loadCookie() {
        if (this.cookieService.getCookie(APPCONFIG.sessionCookieStorageKey)) {
            this.token = JSON.parse( ( this.cookieService.getCookie( APPCONFIG.sessionCookieStorageKey ) ) )
        } else {
            this.token = {};
        }
    }

}


import { createAction, props } from '@ngrx/store';
import { PublicTransport } from '../../models/public-transport.model';

export const setPublicTransport = createAction(
    '[PublicTransport] Set Public Transport Paginated',
    props<{publicTransport: PublicTransport[]}>()
);

export const unsetPublicTransport = createAction(
    '[PublicTransport] Unset Public Transport'
);

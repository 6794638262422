import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AuthenticationService } from '../../services/auth.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { login, loginSuccess, loginFailure, logout } from '../../actions/app/auth.actions';
import { of } from 'rxjs';
import { setAuthenticatedUser, unsetAuthenticatedUser, logoutSuccess, logoutFailure } from '../../actions/user/user.action';
import { User } from '../../models/user.models';
import { CookieService } from '../../services/cookie.service';

@Injectable()
export class AuthEffects {
    constructor(
        private authenticationService: AuthenticationService,
        private cookieService: CookieService,
        private actions$: Actions
    ) {}

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                login
            ),
            mergeMap(
                ( actionLogin ) => {
                    return this.authenticationService.login( actionLogin.credentials )
                        .pipe(
                            map( token => loginSuccess( { token } ) ),
                            // of para crear un observable
                            catchError( error => of( loginFailure( { error } ) ) )
                        );
                }
            )
        );
    });

    loginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
                ofType( loginSuccess ),
                // Disparar el nuevo observable, convinado con el anterior
                mergeMap(
                    () => {
                        return this.authenticationService.me()
                            .pipe(
                                map( (authenticatedUser: User ) => {
                                    if (authenticatedUser.role_slug !== 'role_slug') {
                                        return setAuthenticatedUser( { authenticatedUser } );
                                    } else {
                                        return logout();
                                    }
                                })
                            );
                    }
                )
        );
    });

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType( unsetAuthenticatedUser ),
            mergeMap(
                () => {
                    return this.authenticationService.logout()
                        .pipe(
                            map( () => logoutSuccess(),
                            catchError( error => of( logoutFailure( { error } ) ) ))
                        );
                }
            )
        )
    });

}

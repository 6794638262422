import { createReducer, on } from '@ngrx/store';
import { setMetersScreen } from '../../actions/google/circle.action';

export interface State {
    meters: number;
    screenId: number;
    position: any;
  }
  
export const initialState: State = {
  meters: 0,
  screenId: null,
  position: null
};

const _metersReducer = createReducer(
  initialState,
  on(setMetersScreen, (state, { meters, screenId, position }) => ({
    ...state,
    meters,
    screenId,
    position
  }))
);

export function metersReducer(state, action) {
  return _metersReducer(state, action);
}

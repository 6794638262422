import { createAction, props } from '@ngrx/store';

export const changePage = createAction(
    '[Pagination] Change Page',
    props<{page: number}>()
);

export const resetPage = createAction(
    '[Pagination] Reset Page'
);

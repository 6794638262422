import { createAction, props } from '@ngrx/store';
import { Country } from '../../models/country.model';

export const loadCountries = createAction('[Countries] Load Countries');

export const loadCountriesSuccess = createAction(
    '[Countries] Load Countries Success',
    props<{countries: Country[]}>()
);

export const loadCountriesFailed = createAction(
    '[Countries] Load Countries Failed',
    props<{error: any}>()
);

export const setStatesCountries = createAction(
    '[Companies] Set States Countries',
    props<{statesCountries: any[]}>()
);

export const unsetStatesCountries = createAction(
    '[Companies] Unset States Countries'
);
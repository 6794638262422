import { createReducer, on } from '@ngrx/store';
import { changePage, resetPage } from '../../actions/app/pagination.action';

export interface State {
    page: number; 
}

export const initialState: State = {
   page: 1,
};

const _paginationReducer = createReducer(initialState,

    on(changePage, (state, {page}) => ({ ...state, page})),
    on(resetPage, state => ({ ...state, page: 1})),

);

export function paginationReducer(state, action) {
    return _paginationReducer(state, action);
}

import { createReducer, on } from '@ngrx/store';
import { FilterCampaign } from '../../models/filters-campaign.model';
import { setFiltersCampaign, unsetFiltersCampaign } from '../../actions/campaign/filter.action';

export interface State {
    filters: FilterCampaign | null;
}

export const initialState: State = {
    filters: null
};

const _filterReducer = createReducer(initialState,
    on(setFiltersCampaign, (state, {filters}) => ({
        ...state,
        filters: {...filters}
    })),
    on(unsetFiltersCampaign, state => ({
        ...state,
        filters: null
    }))
);

export function filterReducer(state, action) {
    return _filterReducer(state, action);
}

import { createReducer, on } from '@ngrx/store';
import { setFilteredScreens, setFilters, unsetFilters, unsetFilteredScreens } from '../../actions/directory/directory.action';
import { Screen } from '../../models/screen.model';
import { Filter } from '../../models/filter.models';

export interface State {
    filteredScreens: Screen[] | null;
    filters: Filter | null;
}

export const initialState: State = {
    filteredScreens: null,
    filters: null,
};

const _directoryReducer = createReducer(initialState,

    on(setFilteredScreens, (state, {screens}) => ({
        ...state,
        filteredScreens: [...screens]
    })),
    on(unsetFilteredScreens, state => ({
        ...state,
        filteredScreens: null
    })),
    on(setFilters, (state, {filters}) => ({
        ...state,
        filters: {...filters}
    })),
    on(unsetFilters, state => ({
        ...state,
        filters: null
    }))

);

export function directoryReducer(state, action) {
    return _directoryReducer(state, action);
}

import { createReducer, on } from '@ngrx/store';
import { setWord } from '../../actions/app/search.actions';

export interface State {
    word: string;
}

export const initialState: State = {
   word: '',
};

const _searchReducer = createReducer(initialState,

    on(setWord, (state, {word}) => ({ ...state, word})),

);

export function searchReducer(state, action) {
    return _searchReducer(state, action);
}
import { createAction, props } from '@ngrx/store';
import { Auth } from '../../models/auth.models';

export const login = createAction(
    '[Auth] Login',
    props<{credentials: Auth}>()
);

export const loginSuccess = createAction(
    '[Auth] Login Success',
    props<{ token: any }>()
);

export const loginFailure = createAction(
    '[Auth] Login Failure',
    props<{ error: any }>()
);

export const logout = createAction(
    '[Auth] Logout'
);


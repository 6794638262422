import { createReducer, on } from '@ngrx/store';
import { Campaign } from '../../models/campaign.model';
import { setCampaign } from '../../actions/campaign/campaign.action';

export interface State {
    selectedCampaign: Campaign | null;
}

export const initialState: State = {
    selectedCampaign: null
};

const _campaignReducer = createReducer(initialState,

    on(setCampaign, (state, {selectedCampaign}) => ({ ...state, selectedCampaign: {...selectedCampaign}})),

);

export function campaignReducer(state, action) {
    return _campaignReducer(state, action);
}
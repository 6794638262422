<div id="layout-wrapper">
    <app-horizontaltopbar *ngIf="navbarShowHide"></app-horizontaltopbar>

    <div class="main-content">
        <div [ngClass]="{'page-content': navbarShowHide}" [ngStyle]="flag ? '' : {'margin-top': '20px'}">
            <router-outlet></router-outlet>
        </div>
    </div>

    <!-- <app-footer></app-footer> -->
</div>
import { createAction, props } from '@ngrx/store';

export const showCircles = createAction(
    '[Circle] Show Circles',
    props<{ showCircles: boolean }>()
);

export const setMeters = createAction(
    '[Circle] Set Meters',
    props<{ meters: number }>()
);

export const setMetersScreen = createAction(
    '[Circle] Set Meters Screen',
    props<{ meters: number, screenId: number, position: any }>()
);

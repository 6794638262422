import { createReducer, on } from '@ngrx/store';
import { setImpacts, unsetImpacts } from '../../actions/impacts/impacts.action';

export interface State {
    impacts: any[] | null;
}

export const initialState: State = {
   impacts: []
};

const _impactsReducer = createReducer(initialState,

    on(setImpacts, (state, {impacts}) => (
        {
            ...state,
            impacts: [...impacts]
        }
    )),
    on(unsetImpacts, state => ({ ...state, impacts: null})),

);

export function impactsReducer(state, action) {
    return _impactsReducer(state, action);
}
import { createReducer, on } from '@ngrx/store';
import { MarkerScreen } from '../../models/market.screen.model';
import { setScreenSelected, setDeselectedScreens, setScreensSelecteds, unsetScreensSelecteds, setPublicTransportSelected, setDeselectedPublicTransport, setPublicTransportsSelecteds, unsetPublicTransportsSelecteds } from '../../actions/directory/directory-selecteds-screens.actions';
import { MarkerPublicTransport } from '../../models/marker.public-transport.model';


export interface State {
    markersScreensSelected: MarkerScreen[] | null;
    markersDeselectedScreens: MarkerScreen[] | null;

    markersPublicTransportsSelecteds: MarkerPublicTransport[] | null;
    markersPublicTransportsDeselecteds: MarkerPublicTransport[] | null;
}

export const initialState: State = {
    markersScreensSelected: [],
    markersDeselectedScreens: [],
    markersPublicTransportsSelecteds: [],
    markersPublicTransportsDeselecteds: []
};

const _directorySelectedsScreenReducer = createReducer(initialState,
    on(setScreenSelected, (state, {marker}) => ({
        ...state,
        markersScreensSelected: [...state.markersScreensSelected, {...marker}],
        markersDeselectedScreens: state.markersDeselectedScreens.filter(mD => mD.idScreen !== marker.idScreen)
    })),
    on(setDeselectedScreens, (state, {marker}) => ({
        ...state,
        markersDeselectedScreens: [...state.markersDeselectedScreens, {...marker}],
        markersScreensSelected: state.markersScreensSelected.filter(mS => mS.idScreen !== marker.idScreen)
    })),
    on(setScreensSelecteds, (state, {markers}) => ({
        ...state,
        markersScreensSelected: [...markers],
        markersDeselectedScreens: []
    })),
    on(unsetScreensSelecteds, state => ({
        ...state,
        markersDeselectedScreens: [],
        markersScreensSelected: []
    })),

    on(setPublicTransportSelected, (state, {marker}) => ({
        ...state,
        markersPublicTransportsSelecteds: [...state.markersPublicTransportsSelecteds, {...marker}],
        // tslint:disable-next-line: max-line-length
        markersPublicTransportsDeselecteds: state.markersPublicTransportsDeselecteds.filter(mPT => mPT.idPublicTransport !== marker.idPublicTransport)
    })),
    on(setDeselectedPublicTransport, (state, {marker}) => ({
        ...state,
        markersPublicTransportsDeselecteds: [...state.markersPublicTransportsDeselecteds, {...marker}],
        // tslint:disable-next-line: max-line-length
        markersPublicTransportsSelecteds: state.markersPublicTransportsSelecteds.filter(mPT => mPT.idPublicTransport !== marker.idPublicTransport)
    })),
    on(setPublicTransportsSelecteds, (state, {markers}) => ({
        ...state,
        markersPublicTransportsSelecteds: [...markers],
        markersPublicTransportsDeselecteds: []
    })),
    on(unsetPublicTransportsSelecteds, state => ({
        ...state,
        markersPublicTransportsDeselecteds: [],
        markersPublicTransportsSelecteds: []
    })),

);

export function directorySelectedsScreenReducer(state, action) {
    return _directorySelectedsScreenReducer(state, action);
}

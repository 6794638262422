import { createReducer, on } from '@ngrx/store';
import { setLanguage } from '../../actions/app/language.actions';

export interface State {
    language: string | null;
}

export const initialState: State = {
   language: null,
}

const _languageReducer = createReducer(initialState,

    on(setLanguage, (state, {language}) => ({ ...state, language})),

);

export function languageReducer(state, action) {
    return _languageReducer(state, action);
}
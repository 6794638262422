import { createReducer, on } from '@ngrx/store';
import { setPublicTransport, unsetPublicTransport } from '../../actions/public-transport/public-transports.action';
import { PublicTransport } from '../../models/public-transport.model';

export interface State {
    publicTransport: PublicTransport[] | null;
}

export const initialState: State = {
    publicTransport: []
};

const _publicTransportReducer = createReducer(initialState,

    on(setPublicTransport, (state, {publicTransport}) => ({
        ...state,
        publicTransport: [...publicTransport]
    })),

    on(unsetPublicTransport, state => ({
        ...state,
        publicTransport: []
    })),

);

export function publicTransportReducer(state, action) {
    return _publicTransportReducer(state, action);
}
import { createAction, props } from '@ngrx/store';

export const setPlaces = createAction(
    '[Places] Set Places',
    props<{places: any[]}>()
);

export const setPlace = createAction(
    '[Places] Set Place',
    props<{place: any}>()
);

export const unsetPlaces = createAction(
    '[Places] Unset Places'
);

export const setPlacePublicTransport = createAction(
    '[Places] Set Place Public Transport',
    props<{place: any}>()
);

export const setPlacesPublicTransport = createAction(
    '[Places] Set Places Public Transport',
    props<{places: any[]}>()
);

export const unsetPlacesPublicTransport = createAction(
    '[Places] Unset Places Public Transport'
);

export const setTruePlacesPublicTransports = createAction(
    '[Place] Set True Places Public Transport',
    props<{publicTransportId: number}>()
);

export const setFalsePlacesPublicTransport = createAction(
    '[Place] Set False Places Public Transport',
    props<{publicTransportId: number}>()
);


import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user.models';
import { PaginatedData } from '../../models/paginated-data.model';

export const setUsers = createAction(
    '[Users] Load Users Success',
    props<{usersPaginated: PaginatedData}>()
);

export const unsetUsers = createAction(
    '[Users] Load Users Error'
);

export const unsetUser = createAction(
    '[Users] Unset User',
    props<{userId: number}>()
);

export const searchUsers = createAction(
    '[Users] Search Users',
    props<{word: string}>()
);

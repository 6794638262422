import { createAction, props } from '@ngrx/store';
import { Screen } from '../../models/screen.model';
import { PaginatedData } from '../../models/paginated-data.model';

export const loadScreens = createAction(
    '[Screens] Load Screens',
    props<{ooh: string | undefined, countries_ids: number[] | undefined, states: string[] | undefined, typeS: string | undefined, screen_types: string[]}>()
);

export const loadScreensSuccess = createAction(
    '[Screens] Load Screens Success',
    props<{screens: Screen[]}>()
);

export const loadScreensFailed = createAction(
    '[Screens] Load Screens Failed',
    props<{error: any}>()
);

export const setScreensPaginated = createAction(
    '[Screens] Set Screens Paginated',
    props<{screensPaginated: PaginatedData}>()
);

export const unsetScreensPaginated = createAction(
    '[Screens] Unset Screens Paginated'
);

export const unsetScreen = createAction(
    '[Screens] Unset Screen',
    props<{screenId: number}>()
);

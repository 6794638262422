import { createReducer, on } from '@ngrx/store';
import { updateScreen } from '../../actions/screen/screen.action';
import { Screen } from '../../models/screen.model';

export interface State {
    selectedScreen: Screen| null;
}

export const initialState: State = {
    selectedScreen: null
};

const _screenReducer = createReducer(initialState,

    on(updateScreen, (state, {selectedScreen}) => ({ ...state, selectedScreen: {...selectedScreen}})),

);

export function screenReducer(state, action) {
    return _screenReducer(state, action);
}
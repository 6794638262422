import { createReducer, on } from '@ngrx/store';
import { setPlaces, setPlace, unsetPlaces, setPlacesPublicTransport, unsetPlacesPublicTransport, setPlacePublicTransport, setTruePlacesPublicTransports, setFalsePlacesPublicTransport } from '../../actions/google/places.action';

export interface State {
    places: any[];
    placesPublicTransport: any[];
}

export const initialState: State = {
   places: [],
   placesPublicTransport: []
};

const _placesReducer = createReducer(initialState,

    on(setPlaces, (state, {places}) => ({ ...state, places: [...places]})),
    on(setPlace, (state, {place}) => ({ ...state, places: [...state.places, {...place}]})),
    on(unsetPlaces, state => ({ ...state, places: []})),
    on(setPlacePublicTransport, (state, {place}) => ({ ...state, placesPublicTransport: [...state.placesPublicTransport, {...place}]})),
    on(setPlacesPublicTransport, (state, {places}) => ({ ...state, placesPublicTransport: [...places]})),
    on(unsetPlacesPublicTransport, state => ({ ...state, placesPublicTransport: []})),

    on(setTruePlacesPublicTransports, (state, {publicTransportId}) => (
        {
            ...state,
            placesPublicTransport: state.placesPublicTransport.map( pPT => {
                if (pPT.idPublicTransport === publicTransportId) {
                    return {
                        ...pPT,
                        selected: true
                    };
                }
                return {...pPT};
            })
        }
    )),

    on(setFalsePlacesPublicTransport, (state, {publicTransportId}) => (
        {
            ...state,
            placesPublicTransport: state.placesPublicTransport.map( pPT => {
                if (pPT.idPublicTransport === publicTransportId) {
                    return {
                        ...pPT,
                        selected: false
                    };
                }
                return {...pPT};
            })
        }
    )),

);

export function placesReducer(state, action) {
    return _placesReducer(state, action);
};

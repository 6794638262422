import { Injectable } from '@angular/core';
import { HelpersServicesService } from './helpers-services.service';
import { map } from 'rxjs/operators';
import { Screen } from '../models/screen.model';

@Injectable({
  providedIn: 'root'
})
export class ScreensService {

  constructor(
    private helpers: HelpersServicesService
  ) { }

  addScreen(screen: Screen) {
    return this.helpers.postQuery('screens', screen);
  }

  updateScreen(screen: Screen, screenId: number) {
    return this.helpers.putQuery(`screens/${screenId}`, screen);
  }

  getFilteredScreens(filters) {
    return this.helpers.postQuery('screens/filtered', filters)
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getAllScreens(page = 1) {
    return this.helpers.getQuery(`screens?page=${page}`)
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getAllScreensDisabled(page = 1) {
    return this.helpers.getQuery(`screens/deleteds?page=${page}`)
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getNumberScreensCircuit(circuitId: number) {
    return this.helpers.getQuery(`circuits/${circuitId}/screens/number`)
      .pipe(
        map((resp: any) => resp.message)
      );
  }

  getAllScreensCircuit(circuitId: number) {
    return this.helpers.getQuery(`circuits/${circuitId}/screens`)
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getAllScreensCircuitPromise(circuitId: number) {
    return this.helpers.getQuery(`circuits/${circuitId}/screens`).toPromise();
  }

  getScreensProviderPaginated(companyId: number, page: number = 1) {
    return this.helpers.getQuery(`screens/provider/${companyId}?page=${page}`)
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  deleteImage(img) {
    return this.helpers.postQuery('screens/image/delete', { img });
  }

  deleteImageScreen(id: number) {
    return this.helpers.deleteQuery(`screens/${id}/image/delete`);
  }

  getScreen(screenId: number) {
    return this.helpers.getQuery(`screens/${screenId}`)
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getNumberScreensNameBusiness(nameBusiness: string) {
    return this.helpers.postQuery(`screens/business-name`, { business_name: nameBusiness })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  searchScreens(word: string, page: number = 1) {
    return this.helpers.postQuery(`screens/search?page=${page}`, { word })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  deleteScreen(screenId: number) {
    return this.helpers.deleteQuery(`screens/${screenId}`);
  }

  searchScreenProviderId(screenProviderId: string) {
    return this.helpers.postQuery(`screens/search/provider-id`, { provider_screen_id: screenProviderId })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  searchScreensProviderId(word: string) {
    return this.helpers.postQuery(`screens/search/provider`, { word })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getExcelCampaigns(screenId: number) {
    return this.helpers.getQuery(`screens/${screenId}/export`);
  }

  changeDisabledProvider(screenId: number) {
    return this.helpers.getQuery(`public/disabled/screens/${screenId}`);
  }

  importScreens(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.helpers.postQuery(`screens/import`, formData);
  }

  approveScreen(screenId: number) {
    return this.helpers.getQuery(`screens/approve/${screenId}`);
  }

  getNearbyScreensSameProvider(screenId: number, limit = 1, distance = 10) {
    return this.helpers.postQuery(`screens/${screenId}/nearby/same-provider`, { limit, distance })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getNearbyScreens(screenId: number, limit = 1, distance = 10) {
    return this.helpers.postQuery(`screens/${screenId}/nearby`, { limit, distance })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getScreensRatio(data) {
    return this.helpers.postQuery(`screens/ratio`, { data })
      .pipe(
        map((resp: any) => resp.data)
      );
  }

  getTypes(countries_ids) {
    return this.helpers.postQuery(`screens/types`, {countries_ids})
                      .pipe(
                        map( (resp: any) => resp.data)
                      );
  }

}

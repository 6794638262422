import { createReducer, on } from '@ngrx/store';
import { isLoading, stopLoading, setLogo } from '../../actions/app/ui.actions';

export interface State {
    isLoading: boolean;
    companyImg: string | null;
}

export const initialState: State = {
   isLoading: false,
   companyImg: null
};

const _uiReducer = createReducer(initialState,

    on(isLoading, state => ({ ...state, isLoading: true})),
    on(stopLoading, state => ({ ...state, isLoading: false})),
    on(setLogo, (state, {companyImg}) => ({ ...state, companyImg})),

);

export function uiReducer(state, action) {
    return _uiReducer(state, action);
}
import { createAction, props } from '@ngrx/store';
import { PaginatedData } from '../../models/paginated-data.model';
import { Campaign } from '../../models/campaign.model';

export const setCampaignsPaginated = createAction(
    '[Campaigns] Set Campaigns',
    props<{ campaignsPaginated: PaginatedData }>()
);

export const unsetCampaignsPaginated = createAction(
    '[Campaigns] Unset Campaigns'
);

export const setCampaignDuplicate = createAction(
    '[Campaigns] Set Campaign Duplicate',
    props<{ campaignDuplicate: Campaign }>()
);

export const setCampaignUpdated = createAction(
    '[Campaigns] Set Campaign Updated',
    props<{ campaignUpdated: Campaign }>()
);

export const unsetCampaign = createAction(
    '[Campaigns] Unset Campaign',
    props<{ campaignId: number }>()
);

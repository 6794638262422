import { createAction, props } from '@ngrx/store';
import { MarkerScreen } from '../../models/market.screen.model';
import { MarkerPublicTransport } from '../../models/marker.public-transport.model';

export const setScreenSelected = createAction(
    '[DirectorySelectedsScreens] Set Screen Selected',
    props<{ marker: MarkerScreen }>()
);

export const setDeselectedScreens = createAction(
    '[DirectorySelectedsScreens] Set Deselected Screens',
    props<{ marker: MarkerScreen }>()
);

export const setScreensSelecteds = createAction(
    '[DirectorySelectedsScreens] Set Screens Selecteds',
    props<{ markers: MarkerScreen[] }>()
);

export const unsetScreensSelecteds = createAction(
    '[DirectorySelectedsScreens] Unset Screens Selecteds'
);

export const setPublicTransportSelected = createAction(
    '[DirectorySetPublicTransportSelected] Set Public Transport Selected',
    props<{ marker: MarkerPublicTransport }>()
);

export const setDeselectedPublicTransport = createAction(
    '[DirectorySetDeselectedPublicTransport] Set Deselected Public Transport',
    props<{ marker: MarkerPublicTransport }>()
);

export const setPublicTransportsSelecteds = createAction(
    '[DirectorySetPublicTransportsSelecteds] Set Public Transports Selecteds',
    props<{ markers: MarkerPublicTransport[] }>()
);

export const unsetPublicTransportsSelecteds = createAction(
    '[DirectoryUnsetPublicTransportsSelecteds] Unset Public Transports Selecteds'
);

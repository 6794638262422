import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

// Enviroment
import { environment } from '../environments/environment';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CustomDatepickerI18n } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// NgRX
import { StoreModule } from '@ngrx/store';
import { appReducers } from './app.reducers';

// NgRX Dev Tools
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

// Translate
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// NgRX Effects
import { EffectsModule } from '@ngrx/effects';
import { EffectsArray } from './core/effects/index';

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgbDatepickerI18n, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

// Locales
import localeEsMx from '@angular/common/locales/es-MX';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEsMx, 'es');

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule} from '@angular/fire/database';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutsModule,
    AppRoutingModule,
    HttpClientModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    StoreModule.forRoot( appReducers ),
    EffectsModule.forRoot( EffectsArray ),
    StoreDevtoolsModule.instrument({
      maxAge: 30, // Retains last 30 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    NgbNavModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

import { Pipe, PipeTransform } from '@angular/core';
import { APPCONFIG } from 'src/app/core/config/config';

@Pipe({
  name: 'noimage'
})
export class NoimagePipe implements PipeTransform {

  transform(image: string, type?: string): string {

    switch (type) {
      case 'company':
        if (!image || image === '') {
          return 'assets/images/default/prodooh.png';
        } else {
          return `${APPCONFIG.endpoints.companies_imgs}/${image}`;
        }
        break;

      case 'company-dark':
        if (!image || image === '') {
          return 'https://api.prodooh.com/img/companies/prodooh-dark.png';
        } else {
          return `${APPCONFIG.endpoints.companies_imgs}/${image}`;
        }
        break;

      case 'user':
        if (!image || image === '') {
          return  'assets/images/default/user.png';
        } else {
          return `${ APPCONFIG.endpoints.users_imgs }/${image}`;
        }
        break;

      case 'screen':
        if (!image || image === '') {
          return  'assets/images/default/default.png';
        } else {
          return image;
        }
        break;

      case 'screenUrl':
        if (!image || image === '') {
          return  'assets/images/default/default.png';
        } else {
          return `${ APPCONFIG.endpoints.screens_imgs }/${image}`;
        }
        break;

      case 'mediaContentUrl':
        if (!image || image === '') {
          return  'assets/images/default/default.png';
        } else {
          return `${ APPCONFIG.endpoints.media_contents_imgs }/${image}`;
        }
        break;

        case 'reports':
          if (!image || image === '') {
            return  'assets/images/default/default.png';
          } else {
            return `${ APPCONFIG.endpoints.img_reports }/${image}`;
          }
          break;

        case 'witnesses':
          if (!image || image === '') {
            return  'assets/images/default/default.png';
          } else {
            return `${ APPCONFIG.endpoints.witnesses_imgs }/${image}`;
          }
          break;

        case 'portada':
          if (!image || image === '') {
            return  'assets/images/default/default.png';
          } else {
            return `${ APPCONFIG.endpoints.pdf_portada }/${image}`;
          }
          break;

        case 'pdf_promotion':
          return  'assets/images/default/PDF_image.jpg';

        case 'screen_template':
          if (!image || image === '') {
            return  'assets/images/default/default.png';
          } else {
            return `${APPCONFIG.endpoints.services}/pdfs/screen_templates/${image}`;
          }
          break;

          

      default:
        return 'assets/images/default/default.png';
        break;
    }
  }

}

import { createReducer, on } from '@ngrx/store';
import { Auth } from '../../models/auth.models';
import { login, loginSuccess, loginFailure } from '../../actions/app/auth.actions';

export interface State {
    isAuthenticated: boolean;
    credentials: Auth | null;
    errorMessage: any | null;
    token: string | any;
}

export const initialState: State = {
    isAuthenticated: false,
    credentials: null,
    errorMessage: null,
    token: null
};

const _authReducer = createReducer(initialState,

    on(login, (state, { credentials }) => ({ ...state, credentials: { ...credentials }})),
    on(loginSuccess, (state, { token }) => (
        {
            ...state,
            isAuthenticated: true,
            credentials: null,
            errorMessage: null,
            token: { ...token }
        })),
    on(loginFailure, (state, { error }) => (
        {
            ...state,
            isAuthenticated: false,
            credentials: null,
            errorMessage: { ...error },
            token: null
        }))

);

export function authReducer(state, action) {
    return _authReducer(state, action);
}
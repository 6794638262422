import { createReducer, on } from '@ngrx/store';
import { setUsers, unsetUsers, unsetUser } from '../../actions/user/users.action';
import { PaginatedData } from '../../models/paginated-data.model';


export interface State {
    usersPaginated: PaginatedData | null;
    word: string | null;
}

export const initialState: State = {
    usersPaginated: null,
    word: ''
};

const _usersReducer = createReducer(initialState,

    on(setUsers, (state, {usersPaginated}) => ({ ...state, usersPaginated: {...usersPaginated}})),
    on(unsetUsers, state => ({ ...state, usersPaginated: null})),
    on(unsetUser, (state, {userId}) => ({
        ...state,
        usersPaginated: {
            ...state.usersPaginated,
            data: state.usersPaginated.data.filter(user => user.id !== userId)
        }
    })),

);

export function usersReducer(state, action) {
    return _usersReducer(state, action);
}
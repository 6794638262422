import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { CookieService } from '../services/cookie.service';
import { APPCONFIG } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard implements CanLoad {

  constructor(
    private cookieService: CookieService,
    private router: Router
  ) {}

  canLoad() {
    if (this.cookieService.getCookie( APPCONFIG.sessionCookieStorageKey )) {
      this.router.navigateByUrl('/directory');
      return false;
    } else {
      return true;
    }
  }
}

import { createReducer, on } from '@ngrx/store';
import { User } from '../../models/user.models';
import { setAuthenticatedUser, unsetAuthenticatedUser, logoutSuccess, logoutFailure, updateUser } from '../../actions/user/user.action';

export interface State {
    user: User | null;
    logoutSuccess: boolean;
    logoutFailure: any;
    selectedUser: User;
}

export const initialState: State = {
   user: null,
   logoutSuccess: false,
   logoutFailure: null,
   selectedUser: null
};

const _userReducer = createReducer(initialState,

    on(setAuthenticatedUser, (state, { authenticatedUser }) => ({ ...state, user: { ...authenticatedUser }})),
    on(unsetAuthenticatedUser, state => ({ ...state, user: null})),
    on(logoutSuccess, state => ({ ...state, logoutSuccess: true})),
    on(logoutFailure, (state, { error }) => ({ ...state, logoutFailure: { ...error }})),
    on(updateUser, (state, { selectedUser }) => ({ ...state, selectedUser: { ...selectedUser }}))

);

export function userReducer(state, action) {
    return _userReducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { FilterCampaign } from '../../models/filters-campaign.model';

export const setFiltersCampaign = createAction(
    '[Directory] Set Filters',
    props<{filters: FilterCampaign}>()
);

export const unsetFiltersCampaign = createAction(
    '[Directory] Unset Filters'
);

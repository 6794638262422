import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  itemValue = new BehaviorSubject(this.theItem);

  constructor() { }

  setLocal(name: string, value: any) {
    localStorage.setItem(name, JSON.stringify( value ));
  }

  getLocal(name: string) {
    return JSON.parse(localStorage.getItem(name));
  }

  set theItem(value) {
    this.itemValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('theItem', value);
  }

  get theItem() {
    return localStorage.getItem('theItem');
  }

  deleteLocal(name: string) {
    localStorage.removeItem(name);
  }

  setPolygons( arrPolygons: any ) {
    const polygonsPaths = [];
    arrPolygons.forEach((polygon) => {
      const polygonPaths = [];

      const optionPolygon = {
          color: polygon.fillColor
      };

      polygonPaths.push(optionPolygon);

      polygon.getPath().getArray().map((coordinates) => {
        const coordinatesObj = {
            lat: coordinates.lat(),
            lng: coordinates.lng()
        }
        polygonPaths.push(coordinatesObj);
      });

      polygonsPaths.push(polygonPaths);

    });

    this.setLocal('polygons', JSON.stringify(polygonsPaths));
  }

  setPolygonsCampaign( campaignId, arrPolygons: any ) {
    const polygonsPaths = [];
    arrPolygons.forEach((polygon) => {
      const polygonPaths = [];

      const optionPolygon = {
          color: polygon.fillColor
      };

      polygonPaths.push(optionPolygon);

      polygon.getPath().getArray().map((coordinates) => {
        const coordinatesObj = {
            lat: coordinates.lat(),
            lng: coordinates.lng()
        };
        polygonPaths.push(coordinatesObj);
      });

      polygonsPaths.push(polygonPaths);

    });

    this.setLocal(`polygons${ campaignId }`, JSON.stringify(polygonsPaths));
  }

  getPolygons() {
    return JSON.parse(this.getLocal('polygons'));
  }

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {

  flag: Boolean;

  navbarShowHide: boolean = true;

  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) { }

  ngOnInit() {
    document.body.setAttribute('data-layout', 'horizontal');
    document.body.setAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-sidebar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-sidebar-small');
    this.loadUser();
    this.showHideNavbar();
  }

  ngAfterViewInit() {
  }

  showHideNavbar() {
    if (this.router.url.includes('campaign') && this.router.url.includes('invoice')) {
      this.navbarShowHide = false;
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  loadUser() {
    this.store.select('user').subscribe( ({user}) => {
      if (user?.id) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    });    
  }
}

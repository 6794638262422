import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { loadCountries, loadCountriesSuccess, loadCountriesFailed } from '../../actions/country/countries.action';
import { CountriesService } from '../../services/countries.service';

@Injectable()
export class CountriesEffects {
    // constructor(
    //     private actions$: Actions,
    //     private countriesService: CountriesService
    // ) {}

    // loadCountries$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(
    //             loadCountries
    //         ),
    //         mergeMap(
    //             () => {
    //                 return this.countriesService.getCountries()
    //                     .pipe(
    //                         map( countries => loadCountriesSuccess( { countries } ) ),
    //                         // of para crear un observable
    //                         catchError( error => of( loadCountriesFailed( { error } ) ) )
    //                     );
    //             }
    //         )
    //     );
    // });
}

<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a *ngIf="companyImg" routerLink="/directory" class="logo logo-light">
                    <span class="logo-sm">
                        <img [src]="companyImg | noimage: 'company'" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img [src]="companyImg | noimage: 'company'" alt="" height="48">
                    </span>
                </a>
                <a *ngIf="!isFullAdvertising && !companyImg" routerLink="/directory" class="logo logo-light">
                    <span class="logo-sm">
                        <img [src]="user?.company_img || companyImg | noimage: 'company'" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img [src]="user?.company_img || companyImg | noimage: 'company'" alt="" height="48">
                    </span>
                </a>
                <a *ngIf="isFullAdvertising" routerLink="/directory" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="https://api.prodooh.com/img/companies/Fa7qxIkSMaJF13JvVyIX6Vx8tZ6OBHJX6GWRSUwb.png" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="https://api.prodooh.com/img/companies/Fa7qxIkSMaJF13JvVyIX6Vx8tZ6OBHJX6GWRSUwb.png" alt="" height="48">
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
                <i class="fa fa-fw fa-bars"></i>
            </button>
        </div>

        <div class="d-flex">

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" ngbDropdownToggle>
                    <i class="fas fa-globe-americas fa-2x"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <!-- item-->
                    <a (click)="changeLanguage('es')" class="dropdown-item notify-item text-center">
                        <span class="align-middle">ES</span>
                    </a>

                    <!-- item-->
                    <a (click)="changeLanguage('en')" class="dropdown-item notify-item text-center">
                        <span class="align-middle">EN</span>
                    </a>

                    <!-- item-->
                    <a (click)="changeLanguage('pt')" class="dropdown-item notify-item text-center">
                        <span class="align-middle">BR</span>
                    </a>
                </div>
            </div>

            <div class="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
                    <i class="bx bx-fullscreen"></i>
                </button>
            </div>

            <div *ngIf="!user?.name" class="dropdown d-none d-lg-inline-block ml-1">
                <button routerLink="/account/login" type="button" class="btn header-item noti-icon">
                    Login
                </button>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown>
                <button *ngIf="user?.name" type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown" ngbDropdownToggle>
                    <i class="bx bxs-slideshow" [class.bx-tada]="totalScreensSelected > 0"></i>
                    <span class="badge badge-danger badge-pill">{{ totalScreensSelected }}</span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0" ngbDropdownMenu aria-labelledby="page-header-notifications-dropdown">
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0" style="font-size: 18px;"> {{ 'directory.selectedScreens' | translate }} {{ totalScreensSelected }}</h6>
                            </div>
                            <div class="col">
                                <h6 class="m-0 mt-2" style="font-size: 18px;"> {{ 'directory.selectedPoints' | translate }}: {{ pointsSelected }}</h6>
                            </div>
                            <div class="col">
                                <h6 class="m-0 mt-2" style="font-size: 18px;"> {{ 'directory.selectedTransports' | translate }}: {{ totalPublicTransportsSelected }}</h6>
                            </div>
                            <!-- <div class="col-auto">
                                <a href="#!" class="small">{{ 'general.viewAll' | translate }}</a>
                            </div> -->
                        </div>
                    </div>
                    <perfect-scrollbar style="height: auto" [config]="configData">
                        <a routerLink="/add-screens-campaign" class="text-reset notification-item">
                            <div class="media pointer">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                                        <i class="bx bx-cart"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{'directory.addScreensExistingCampaigns' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'directory.goListAvailableCampaigns' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a (click)="createNewCampaign()" class="text-reset notification-item">
                            <div class="media pointer">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                                        <i class="fas fa-plus-circle"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{'directory.createNewCampaign' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'directory.createANewCampaign' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a (click)="removeScreensSelecteds()" class="text-reset notification-item">
                            <div class="media pointer">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-danger rounded-circle font-size-16">
                                        <i class="fas fa-trash"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{'directory.deleteSelectedScreens' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'directory.removeAllSelectedScreens' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a (click)="removePointsSelecteds()" class="text-reset notification-item">
                            <div class="media pointer">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-danger rounded-circle font-size-16">
                                        <i class="fas fa-trash"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">{{'directory.deleteSelectedPoints' | translate}}</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1">{{ 'directory.removeAllSelectedPoints' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </perfect-scrollbar>
                </div>
            </div>

            <div *ngIf="user?.name" class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                    <img class="rounded-circle header-profile-user" [src]="user?.img_profile | noimage: 'user'" alt="Header Avatar">
                    <span class="d-none d-xl-inline-block ml-1">{{ user?.name | titlecase }}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                    <!-- item-->
                    <a routerLink="/profile" class="dropdown-item" href="javascript: void(0);">
                        <i class="bx bx-user font-size-16 align-middle mr-1"></i> Profile
                    </a>
                    <div class="dropdown-divider"></div>
                    <a (click)="logout()" class="dropdown-item text-danger" href="javascript: void(0);">
                        <i class=" bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout
                    </a>
                </div>
            </div>

        </div>
    </div>
</header>

<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">

            <div *ngIf="user?.name" class="collapse navbar-collapse active" id="topnav-menu-content">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="/directory" routerLinkActive="active" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-book-open mr-2"></i>{{ 'general.directory' | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/directory-traditional" routerLinkActive="active" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-book-open mr-2"></i>{{ 'general.directoryTraditional' | translate }}
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a routerLink="/directory-public-transport" routerLinkActive="active" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-book-open mr-2"></i>{{ 'general.directoryPublicTransport' | translate }}
                        </a>
                    </li> -->

                    <li class="nav-item">
                        <a routerLink="/campaigns/my-campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-book-open mr-2"></i>{{ 'navbar.myCampaigns' | translate }}
                        </a>
                    </li>

                    <li class="nav-item">
                        <a routerLink="/plannings/my-plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-book-open mr-2"></i>{{ 'navbar.myPlannings' | translate }}
                        </a>
                    </li>

                    <li class="nav-item" *ngIf="user?.role_slug == 'administratorprovider'">
                        <a routerLink="/creatives/approvals" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-image"></i> {{ 'general.creatives' | translate }}
                        </a>
                    </li>

                    <li class="nav-item" *ngIf="user?.role_slug == 'administrator' || user?.role_slug == 'superadministrator' ">
                        <a routerLink="/creatives/general/approvals" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link arrow-none" id="topnav-components" role="button">
                            <i class="fas fa-image"></i> {{ 'general.creatives' | translate }}
                        </a>
                    </li>

                    <li *ngIf="user?.role_slug == 'superadministrator' || user?.role_slug == 'administrator'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-components" role="button">
                            <i class="fas fa-user-astronaut mr-2"></i>{{ 'roles.' + user?.role_slug | translate }}
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu menu-colums" aria-labelledby="topnav-dashboard">
                            <a routerLink="/campaigns/my-campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myCampaigns' | translate }}
                            </a>
                            <a routerLink="/campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.campaigns' | translate }}
                            </a>
                            <a routerLink="/plannings/my-plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myPlannings' | translate }}
                            </a>
                            <a routerLink="/plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.plannings' | translate }}
                            </a>
                            <a routerLink="/users" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.users' | translate }}
                            </a>
                            <a routerLink="/administrator/screens" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.active-screens' | translate }}
                            </a>
                            <a routerLink="/administrator/screens-disabled" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.screens-off' | translate }}
                            </a>
                            <a routerLink="/administrator/public-transports" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.publicTranspots' | translate }}
                            </a>
                            <a routerLink="/administrator/circuits-screen" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.screensCircuits' | translate }}
                            </a>
                            <a routerLink="/administrator/companies" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.companies' | translate }}
                            </a>
                            <a routerLink="/administrator/countries" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.countries' | translate }}
                            </a>
                            <a routerLink="/administrator/tags" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                Tags
                            </a>
                            <a routerLink="/particular-points" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.particularPoints' | translate }}
                            </a>
                            <a routerLink="/kml-kmz" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                KML/KMZ
                            </a>
                            <a *ngIf="user?.role_slug === 'superadministrator'" routerLink="/administrator/teams" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                Teams
                            </a>
                            <a routerLink="/administrator/impacts" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.impacts' | translate }}
                            </a>

                            <a routerLink="/administrator/impacts-reports" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.impactsReports' | translate }}
                            </a>

                            <a routerLink="/administrator/files" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.files' | translate }}
                            </a>
                            <a routerLink="/administrator/witnesses" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'screen.photographicWitnesses' | translate }}
                            </a>
                            <a routerLink="/administrator/media-contents/list" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.mediaContent' | translate }}
                            </a>
                            <a routerLink="/administrator/creatives/template" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                Plantillas creativos
                            </a>
                            <a routerLink="/administrator/promotions" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.promotions' | translate }}
                            </a>
                            <a routerLink="/administrator/logs" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                Logs
                            </a>
                            <a routerLink="/administrator/templates" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                Plantillas pantallas
                            </a>
                        </div>
                    </li>

                    <!-- *ngIf="user?.role_slug == 'salesmanager'" -->
                    <li *ngIf="user?.role_slug == 'salesmanager'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-components" role="button">
                            <i class="fas fa-user-tie mr-2"></i>{{ 'roles.' + user?.role_slug | translate }}
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a routerLink="/campaigns/my-campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myCampaigns' | translate }}
                            </a>
                            <a routerLink="/campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.campaigns' | translate }}
                            </a>
                            <a routerLink="/plannings/my-plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myPlannings' | translate }}
                            </a>
                            <a routerLink="/plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.plannings' | translate }}
                            </a>
                            <a routerLink="/users" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.users' | translate }}
                            </a>
                            <a routerLink="/particular-points" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.particularPoints' | translate }}
                            </a>
                            <a routerLink="/kml-kmz" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                KML/KMZ
                            </a>
                        </div>
                    </li>

                    <!-- *ngIf="user?.role_slug == 'administratorprovider'" -->
                    <li *ngIf="user?.role_slug == 'administratorprovider'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-components" role="button">
                            <i class="fas fa-user-tie mr-2"></i>{{ 'roles.' + user?.role_slug | translate }}
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a routerLink="/provider/inventory" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.inventory' | translate }}
                            </a>
                            <a routerLink="/particular-points" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.particularPoints' | translate }}
                            </a>
                            <a routerLink="/kml-kmz" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                KML/KMZ
                            </a>
                        </div>
                    </li>

                    <!-- *ngIf="user?.role_slug == 'sellercosts' || user?.role_slug == 'seller'" -->
                    <li *ngIf="user?.role_slug == 'sellercosts' || user?.role_slug == 'seller'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-components" role="button">
                            <i class="fas fa-user-tie mr-2"></i>{{ 'general.seller' | translate }}
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a routerLink="/campaigns/my-campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myCampaigns' | translate }}
                            </a>
                            <a routerLink="/plannings/my-plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myPlannings' | translate }}
                            </a>
                            <a routerLink="/users" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.customers' | translate }}
                            </a>
                            <a routerLink="/particular-points" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.particularPoints' | translate }}
                            </a>
                            <a routerLink="/kml-kmz" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                KML/KMZ
                            </a>
                        </div>
                    </li>

                    <!-- *ngIf="user?.role_slug == 'customercosts' || user?.role_slug == 'customer'" -->
                    <li *ngIf="user?.role_slug == 'customercosts' || user?.role_slug == 'customer'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-components" role="button">
                            <i class="fas fa-user-tie mr-2"></i>{{ 'general.customer' | translate }}
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a routerLink="/campaigns/my-campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myCampaigns' | translate }}
                            </a>
                            <a routerLink="/campaigns" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.campaigns' | translate }}
                            </a>
                            <a routerLink="/plannings/my-plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.myPlannings' | translate }}
                            </a>
                            <a routerLink="/plannings" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="dropdown-item side-nav-link-ref">
                                {{ 'navbar.plannings' | translate }}
                            </a>
                            <a routerLink="/particular-points" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                {{ 'general.particularPoints' | translate }}
                            </a>
                            <a routerLink="/kml-kmz" routerLinkActive="active" class="dropdown-item side-nav-link-ref">
                                KML/KMZ
                            </a>
                        </div>
                    </li>
                </ul>
            </div>

        </nav>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { loadScreens, loadScreensFailed, loadScreensSuccess } from '../../actions/screen/screens.action';
import { ScreensService } from '../../services/screens.service';
import { setFilteredScreens } from '../../actions/directory/directory.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';

@Injectable()
export class ScreensEffects {
    constructor(
        private actions$: Actions,
        private screensService: ScreensService,
        private store: Store<AppState>
    ) {}

    loadScreens$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                loadScreens
            ),
            mergeMap(
                // tslint:disable-next-line: no-shadowed-variable
                ( loadScreens ) => {
                    return this.screensService
                        .getFilteredScreens(
                            {
                                ooh: loadScreens.ooh,
                                countries_ids: loadScreens.countries_ids,
                                states: loadScreens.states,
                                type: loadScreens.typeS,
                                screen_types: loadScreens.screen_types
                            }
                        )
                        .pipe(
                            switchMap( screens => [
                                loadScreensSuccess( { screens } ),
                                setFilteredScreens( { screens } )
                            ]),
                            // of para crear un observable
                            catchError( error => of( loadScreensFailed( { error } ) ) )
                        );
                }
            )
        );
    });


}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './auth.service';
import { APPCONFIG } from '../config/config';
import { Campaign } from '../models/campaign.model';
import { parse, min, format, max } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class HelpersServicesService {

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient
  ) { }

  getQuery( query: string ) {
    const headers = new HttpHeaders({
      Authorization: `${ this.authenticationService.token.token_type } ${ this.authenticationService.token.access_token }`
    });
    const URL = `${ APPCONFIG.endpoints.services }/${ query }`;
    return this.http.get( URL, { headers } );
  }

  getQueryFile( query: string ) {
    const headers = new HttpHeaders({
      Authorization: `${ this.authenticationService.token.token_type } ${ this.authenticationService.token.access_token }`,
      responseType: 'arraybuffer'
    });
    const URL = `${ APPCONFIG.endpoints.services }/${ query }`;
    return this.http.get( URL, { headers } );
  }

  postQuery( query: string, data: any ) {
    const headers = new HttpHeaders({
      Authorization: `${ this.authenticationService.token.token_type } ${ this.authenticationService.token.access_token }`
    });
    return this.http.post( `${ APPCONFIG.endpoints.services }/${ query }`, data, { headers } );
  }

  putQuery( query: string, data: any ) {
    const headers = new HttpHeaders({
      Authorization: `${ this.authenticationService.token.token_type } ${ this.authenticationService.token.access_token }`
    });
    return this.http.put( `${ APPCONFIG.endpoints.services }/${ query }`, data, { headers } );
  }

  deleteQuery( query: string ) {
    const headers = new HttpHeaders({
      Authorization: `${ this.authenticationService.token.token_type } ${ this.authenticationService.token.access_token }`
    });
    return this.http.delete( `${ APPCONFIG.endpoints.services }/${ query }`, { headers } );
  }

  getFirstDateBudgetCircuits(campaign: Campaign) {
    if (!campaign.budget_circuit?.length) {
      return null;
    }

    const dates = [];
    
    campaign.budget_circuit.forEach(circuit => {
      const year = circuit.payload.dates[0].year;
      const month = circuit.payload.dates[0].month;
      const day = circuit.payload.dates[0].day.toString().padStart(2, '0');
      let date = `${year}-${month}-${day}`;
      dates.push(
        parse(date, 'yyyy-MM-dd', new Date())
      );
    });

    const oldestDate = min(dates);

    return format(oldestDate, 'yyyy-MM-dd') ?? null;
  }

  getLastDateBudgetCircuits(campaign: Campaign) {
    if (!campaign.budget_circuit?.length) {
      return null;
    }

    const dates = [];
    
    campaign.budget_circuit.forEach(circuit => {
      const year = circuit.payload.dates[circuit.payload.dates.length - 1].year;
      const month = circuit.payload.dates[circuit.payload.dates.length - 1].month;
      const day = circuit.payload.dates[circuit.payload.dates.length - 1].day.toString().padStart(2, '0');
      let date = `${year}-${month}-${day}`;
      dates.push(
        parse(date, 'yyyy-MM-dd', new Date())
      );
    });

    const mostRecentDate = max(dates);

    return format(mostRecentDate, 'yyyy-MM-dd') ?? null;
  }
}

import { createReducer, on } from '@ngrx/store';
import { Company } from '../../models/company.model';
import { setCompany } from '../../actions/company/company.action';

export interface State {
    selectedCompany: Company | null;
}

export const initialState: State = {
   selectedCompany: null
}

const _companyReducer = createReducer(initialState,

    on(setCompany, (state, {selectedCompany}) => ({ ...state, selectedCompany: {...selectedCompany}})),

);

export function companyReducer(state, action) {
    return _companyReducer(state, action);
}
import { createAction, props } from '@ngrx/store';
import { Screen } from '../../models/screen.model';
import { Filter } from '../../models/filter.models';

export const setFilteredScreens = createAction(
    '[Directory] Set Filtered Screens',
    props<{screens: Screen[]}>()
);

export const unsetFilteredScreens = createAction(
    '[Directory] Unset Filtered Screens'
);

export const setFilters = createAction(
    '[Directory] Set Filters',
    props<{filters: Filter}>()
);

export const unsetFilters = createAction(
    '[Directory] Unset Filters'
);

import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user.models';

export const setAuthenticatedUser = createAction(
    '[User] Set Authenticated User',
    props<{ authenticatedUser: User }>()
);

export const unsetAuthenticatedUser = createAction(
    '[User] Unset Authenticated User'
);

export const logoutSuccess = createAction(
    '[User] Logout Success'
);

export const logoutFailure = createAction(
    '[User] Logout Failure',
    props<{ error: any }>()
);

export const updateUser = createAction(
    '[Users] Update User',
    props<{ selectedUser: User }>()
);

